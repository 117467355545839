import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

@Pipe({ name: 'fromnow' })
export class DateFromNowPipePipw implements PipeTransform {
  transform(timestamp: number): string {
    return moment(timestamp * 1000).fromNow();
  }
}

@Pipe({ name: 'fromnowlimited', standalone: true })
export class DateFromNowLimitedPipe implements PipeTransform {
  transform(timestamp: number): string {
    const now = moment();
    const time = moment(timestamp * 1000);
    if (now.diff(time, 'days') <= 2) {
      return time.fromNow();
    } else {
      return time.format('DD/MM - HH:mm');
    }
  }
}

@Pipe({ name: 'datetime', standalone: true })
export class DateTimePipe implements PipeTransform {
  transform(timestamp: number): string {
    return moment(timestamp * 1000).format('DD/MM/YYYY HH:mm');
  }
}
