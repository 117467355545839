<div class="lead-picker-container px-3">
  <div class="lead-picker-wrapper" [ngStyle]="{'transform': 'translateY(' + translateY + 'px)'}">
    <div *ngFor="let option of options; let i = index" 
         [ngClass]="{'selected': i === selectedIndex}"
         class="lead-picker-item"
         (click)="selectOption(i)">
      {{ option.value }}
    </div>
  </div>
  <div class="lead-picker-overlay"></div>
</div>
