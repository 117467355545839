import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-lead-options-picker',
  templateUrl: './lead-options-picker.component.html',
  styleUrl: './lead-options-picker.component.scss'
})
export class LeadOptionsPickerComponent implements OnInit, OnChanges {
  @Input() options: {key: string; value: string;}[] = [{key: 'key1', value: 'Opção 1'}, {key: 'key2', value: 'Opção 2'}, {key: 'key3', value: 'Opção 3'}];
  @Output() optionSelected = new EventEmitter<{key: string; value: string;}>();
  @Input() selectedIndex: number = 0;
  translateY: number = 0;

  get selectedKey() {
    return this.options[this.selectedIndex]?.key;
  }

  get selectedValue() {
    return this.options[this.selectedIndex]?.value;
  }

  public selectOption(index: number) {
    this.selectedIndex = index;
    this.updateTranslateY();
    this.emitSelectedOption();
  }

  public ngOnInit() {
    if (this.options.length > 0) {
      this.updateTranslateY();
      this.emitSelectedOption();
    }    
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this._validateSelectedIndex();
      this.updateTranslateY();
      this.emitSelectedOption();
    }
  }
  private _validateSelectedIndex() {
    if (this.selectedIndex >= this.options.length) {
      this.selectedIndex = this.options.length - 1;
    }
  }

  private updateTranslateY() {
    const containerHeight = 150; // altura do container do seletor
    const itemHeight = 50; // altura de cada item
    this.translateY = -(this.selectedIndex * itemHeight) + (containerHeight / 2) - (itemHeight / 2);
  }

  private emitSelectedOption() {
    if (this.options.length > 0) {
      this.optionSelected.emit(this.options[this.selectedIndex]);
    }
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    event.preventDefault();
    if (event.deltaY > 0 && this.selectedIndex < this.options.length - 1) {
      this.selectOption(this.selectedIndex + 1);
    } else if (event.deltaY < 0 && this.selectedIndex > 0) {
      this.selectOption(this.selectedIndex - 1);
    }
  }
}
