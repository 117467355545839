import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PhonePipe } from "./pipes/phonenumber.pipe";
import { LoadingTextComponent } from "./components/loading-text/loading-text.component";
import { DateFromNowLimitedPipe, DateFromNowPipePipw } from "./pipes/dates.pipe";
import { OrderByPipe } from "./pipes/order.pipe";
import { LeadOptionsPickerComponent } from "./components/lead-options-picker/lead-options-picker.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule, 
    FormsModule, 
    NgbModule,
    NgOptimizedImage,
  ],
  exports: [
    CommonModule,
    NgOptimizedImage,
    ReactiveFormsModule, 
    FormsModule, 
    NgbModule,
    PhonePipe,
    DateFromNowPipePipw,
    OrderByPipe,
    LoadingTextComponent,
    LeadOptionsPickerComponent
  ],
  declarations: [
    PhonePipe,
    DateFromNowPipePipw,
    OrderByPipe,
    LoadingTextComponent,
    LeadOptionsPickerComponent
  ],
})
export class SharedModule {}
